.RootHome {
  background-color: theme-color("bgColor");
  height: 50vh;
}
// .RootForMe-inside {
//   height: 100%;
// }

@media (max-width: 991px) {
  .RootHome {
    height: auto;
  }
}
