.RootTwerk {
  background-color: theme-color("bgColor");
  height: 50vh;
  max-width: none;
}
@media (max-width: 991px) {
  .RootTwerk {
    height: auto;
  }
}
