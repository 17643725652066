* {
  font-family: "league-gothic";
  // transition: 0.3s;
}
* span {
  font-family: "Blackink";
}
* span::after {
  font-family: "league-gothic";
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

body,
html {
  font-family: "league-gothic";

  background-color: #000;
  cursor: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

// .h1Title {
//   margin-top: 0.5rem;
//   font-size: 49px;
//   line-height: 40px;
//   text-transform: uppercase;
// }
// .h3Title {
//   font-size: 23px;
//   margin-bottom: 0;
// }

// CURSOR
.cursor {
  width: 40px;
  height: 40px;
  border: 2px solid #fefefe;
  border-radius: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  mix-blend-mode: difference;
  transition: all 150ms ease;
  transition-property: opacity, background-color, transform, mix-blend-mode;
}
.cursor--hidden {
  opacity: 0;
}
.cursor--clicked {
  transform: translate(-50%, -50%) scale(0.9);
  background-color: #fefefe;
}
.cursor--link-hovered {
  transform: translate(-50%, -50%) scale(1.25);
  background-color: #fefefe;
}
.cursor--img-hovered {
  transform: translate(-50%, -50%) scale(1.25);
  background-color: #fefefe;
}

a {
  text-decoration: underline;
  // color: #fefefe;
}
a:link {
  text-decoration: none;
}

.canvasStyle {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  // z-index: 1;
}
.boldText {
  color: theme-color("primary") !important;
}

@media (min-width: 1440px) {
  .h1Title {
    font-size: 69px;
    line-height: 60px;
    text-transform: uppercase;
  }
}

@media (min-width: 1440px) and (max-height: 800px) {
  .h1Title {
    font-size: 8vh !important;
    line-height: 8vh !important;
    text-transform: uppercase;
  }
  .h3Title {
    font-size: 3vh !important;
  }
  // .h1Title {
  //   font-size: 89px;
  //   line-height: 80px;
  //   text-transform: uppercase;
  // }
}
@media (max-width: 1440px) and (min-width: 991px) {
  .h1Title {
    font-size: 3vw !important;
    line-height: 3vw !important;
    text-transform: uppercase;
  }
  .h3Title {
    font-size: 1.5vw !important;
  }
}

@media (max-width: 991px) {
  .h1Title {
    font-size: 10vw;
    line-height: 10vw;
    text-transform: uppercase;
  }
  .h3Title {
    font-size: 4vw;
  }
}

@media (max-height: 700px) and (min-width: 991px) {
  // .h1Title {
  //   font-size: 59px;
  //   line-height: 50px;
  //   text-transform: uppercase;
  // }
  // .h3Title {
  //   font-size: 2vw;
  // }
}

@media (max-width: 666px) {
  .h1Title {
    font-size: 14vw;
    line-height: 13vw;
    text-transform: uppercase;
  }

  .h3Title {
    font-size: 7vw;
  }
}

@media (max-width: 400px) {
  // .h1Title {
  //   font-size: 8vw;
  //   line-height: 8vw;
  //   text-transform: uppercase;
  // }
}

// ANIMATIONS
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
