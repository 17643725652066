.homeSectionCol {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  //   align-items: center;
}

.homeSectionColForm {
  height: 100%;
  padding-bottom: 20px;
  color: theme-color("secondary");
  text-align: left;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.formContent {
  width: fit-content;
  height: fit-content;
  // bottom: 0;
  // left: 50%;
  position: absolute;
  // transform: translate(-50%);
}

.homeSectionImg {
  position: relative;
  // width: 411px;
  left: -60px;
  height: 100%;
}

.imgHomeSection {
  // position: relative;
  // right: 10%;
  height: 100%;
}

.formImg {
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   transform: translateX(-25%);
}

.discountBg {
  position: absolute;
  background-image: url("../../../../assets/img/icons/discountBg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 115.34px;
  height: 89.19px;
  left: -60px;
  top: -65px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}

.discountBg::after {
  content: "10 €";
  transform: rotate(-16.85deg);
  font-family: "Blackink";
  font-size: 50px;
}

// FORM
// ._field11 {
//   position: absolute !important;
//   bottom: -70px;
// }
._form {
  width: 100% !important;
  padding-left: 0 !important;
  margin-top: 0 !important;
}

._form-label {
  display: none !important;
}

#_form_5F2D0E9113170_._inline-style {
  width: 100%;
  display: inline-block;
}

._x64724438 input {
  color: #fff;
}

._field-wrapper input {
  background-color: transparent;
  padding-left: 15px !important;
  padding-right: 15px !important;
  height: 50px !important;
  font-size: 1.5em !important;
}

// ._inline-style input[type="text"] {
//   width: 350px !important;
//   font-size: 20px !important;
//   background-color: transparent;
//   background-image: url("../../../../assets/img/icons/olivialucia-input.svg");
//   background-size: 100%;
//   background-repeat: no-repeat;
//   background-position: center;
//   border: none !important;
// }
#_form_140_submit {
  font-family: "league-gothic" !important;
  font-size: 20px !important;
  line-height: initial;
  letter-spacing: 0.4px;
  width: 100%;
  height: 64px;
  background-color: transparent !important;
  position: relative;

  margin: 10px;

  z-index: 1;
}

#_form_140_submit::after {
  content: "";
  height: 64px;
  width: 100%;

  background-image: url("../../../../assets/img/icons/olivialucia-boton.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100% !important;

  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

._error-inner._no_arrow {
  display: none;
}

._inline-style:not(._clear)+._inline-style:not(._clear) {
  margin-left: 0 !important;
}

._button-wrapper {
  margin: 0 !important;
}

._inline-style:not(._clear)+._inline-style:not(._clear) {
  margin-left: 20px !important;
}

._inline-style input[type="text"],
._inline-style input[type="date"] {
  padding: 10px 28px !important;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
  background-image: url("../../../../assets/img/icons/olivialucia-input.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

._form-thank-you {
  text-align: left !important;
}

// ._field11 {
//   position: absolute !important;
//   bottom: -40px;
// }
._form {
  padding-top: 0 !important;
}

input[type="checkbox"]._has_error {
  outline: theme-color("primary") 1px solid !important;
  animation: shake 0.5s;
}

._form_element._clear {
  margin-bottom: 0 !important;
}

.policyStyle {
  color: #fff;
  text-decoration: underline !important;
}

// @media (min-width: 1400px) {
//   ._inline-style input[type="text"] {
//     width: 15vw !important;
//     height: 7vw !important;
//   }
//   #_form_140_submit {
//     font-family: "league-gothic" !important;
//     font-size: 20px !important;
//     line-height: 18px !important;
//     letter-spacing: 0.4px;
//     background-color: transparent !important;
//     position: relative;
//     width: 8vw;
//     height: 7vw;
//   }
//   #_form_140_submit::after {
//     width: 8vw;
//     height: 7vw;
//   }
//   ._field11 {
//     position: absolute !important;
//     bottom: -20px;
//   }
//   ._form {
//     padding-top: 0 !important;
//   }
// }
@media (min-width: 1440px) {
  ._row input[type="checkbox"]+span label {
    font-size: 1vw !important;
  }

  input[type="checkbox"] {
    width: 0.7vw;
    height: 0.7vw;
  }
}

@media (max-width: 1013px) and (min-width: 991px) {
  ._inline-style:not(._clear)+._inline-style:not(._clear) {
    margin-left: 0 !important;
  }
}

@media (min-height: 900px) {
  .imgHomeSection {
    position: relative;
    // left: 50%;
    height: 100%;
    // transform: translate(-62.5%) !important;
  }
}

@media (min-width: 991px) and (max-width: 1440px) {

  // FORM
  // ._inline-style input[type="text"] {
  //   width: 235px !important;
  // }
  #_form_140_submit {
    font-family: "league-gothic" !important;
    font-size: 20px !important;
    line-height: 18px !important;
    letter-spacing: 0.4px;
    height: 54px;
    background-color: transparent !important;
    position: relative;
    width: 100%;
    height: 64px;
  }

  #_form_140_submit::after {
    height: 64px;
    width: 100%;
    background-size: 100% 100% !important;
  }

  // ._field11 {
  //   position: absolute !important;
  //   bottom: -20px;
  // }
  ._form {
    padding-top: 0 !important;
  }
}

@media (max-height: 700px) and (min-width: 991px) {

  // FORM
  // ._inline-style input[type="text"] {
  //   width: 235px !important;
  // }
  // #_form_140_submit {
  //   font-family: "league-gothic" !important;
  //   font-size: 15px !important;
  //   line-height: 15px !important;
  //   letter-spacing: 0.4px;
  //   height: 54px;
  //   background-color: transparent !important;
  //   position: relative;
  //   width: 125px;
  //   height: 74px;
  // }
  // #_form_140_submit::after {
  //   height: 74px;
  //   width: 125px;
  // }
  // ._field11 {
  //   position: absolute !important;
  //   bottom: -20px;
  // }
  ._form {
    padding-top: 0 !important;
  }

  // IMG
  .homeSectionImg {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .imgHomeSection {
    height: 90%;
  }
}

@media (max-height: 960px) {

  // ._field11 {
  //   position: absolute !important;
  //   bottom: -40px;
  // }
  ._form {
    padding-top: 0 !important;
  }
}

@media (max-width: 991px) {
  .homeSectionRow {
    width: 100%;
    margin: 0 auto;
  }

  .formContent {
    margin-top: 100px;
    position: relative;
  }

  .homeSectionImg {
    height: auto;
    width: 100%;
    margin-top: 100px;
  }

  .twerkSectionImg2 {
    width: 100% !important;
    height: auto !important;
    // display: flex;
    text-align: left;
  }

  .imgTwerkSection {
    // position: absolute !important;
    left: 0 !important;
    width: 95% !important;
    height: auto !important;
    margin-top: 100px;
  }

  .twerkSectionCol {
    padding: 0 !important;
  }

  .imgHomeSection {
    width: 80%;
  }

  .discountBg {
    top: -8vw;
    left: -8vw;
  }
}

@media (max-width: 991px) {
  // FORM
  // ._inline-style input[type="text"] {
  //   width: 235px !important;
  // }

  // ._inline-style input[type="text"] {
  //   float: left !important;
  //   display: inline-block !important;
  //   width: 300px !important;
  //   height: 100px !important;
  // }
  // #_form_140_submit {
  //   font-family: "league-gothic" !important;
  //   font-size: 20px !important;
  //   line-height: 18px !important;
  //   letter-spacing: 0.4px;
  //   height: 54px;
  //   background-color: transparent !important;
  //   position: relative;
  //   width: 185px;
  //   height: 100px;
  //   float: left !important;
  // }
  // #_form_140_submit::after {
  //   height: 100px;
  //   width: 185px;
  // }
  // ._field11 {
  //   position: absolute !important;
  //   bottom: -20px;
  // }
  ._form {
    padding-top: 0 !important;
  }

  // ._form-content {
  //   display: flex;
  // }
  // #_form_5F2D2935168A2_ ._form-element,
  // #_form_5F2D2935168A2_ ._inline-style,
  // #_form_5F2D2935168A2_ input[type="text"],
  // #_form_5F2D2935168A2_ label,
  // #_form_5F2D2935168A2_ p,
  // #_form_5F2D2935168A2_ textarea:not(.g-recaptcha-response) {
  //   float: left;
  //   display: block;
  //   width: fit-content !important;
  // }
}

@media (min-height: 800px) {
  .policyStyle {
    font-size: 1.3em !important;
  }
}

@media (max-width: 666px) {
  .formContent {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  ._form_140 {
    display: flex;
    justify-content: center;
  }

  ._submit {
    left: 50%;
    transform: translate(-50%);
  }

  // ._field11 {
  //   bottom: -100px;
  //   width: fit-content !important;
  //   // CENTER ON MOBILE
  //   left: 50% !important;
  //   transform: translate(-50%) !important;
  // }
  // ._field11 {
  //   bottom: -50px;
  // }
  .discountBg {
    width: 20vw;
    height: 17vw;
    top: -11.5vw;
    left: -10vw;
  }

  .discountBg::after {
    content: "10 €";
    transform: rotate(-16.85deg);
    font-family: "Blackink";
    font-size: 8vw;
  }

  ._inline-style:not(._clear)+._inline-style:not(._clear) {
    margin-left: 20px !important;
  }

  .formBgSize {
    text-align: left; // CENTER ON MOBILE: center
  }

  // ._inline-style input[type="text"] {
  //   width: 80vw !important;
  //   height: 20vw !important;
  //   min-width: 150px;
  // }
  // #_form_140_submit {
  //   width: 40vw;
  //   height: 15vw;
  //   min-width: 100px;
  // }
  // #_form_140_submit::after {
  //   width: 40vw;
  //   height: 15vw;
  //   min-width: 100px;
  // }
}

@media (min-width: 400px) and(max-width: 666px) {
  // #_form_140_submit {
  //   margin-top: 20px;
  // }
}

@media (max-width: 500px) {

  // ._inline-style input[type="text"] {
  //   width: 80vw !important;
  //   height: 20vw !important;
  //   min-width: 150px;
  // }
  // #_form_140_submit {
  //   width: 50vw;
  //   height: 20vw;
  //   min-width: 100px;
  // }
  // #_form_140_submit::after {
  //   width: 50vw;
  //   height: 20vw;
  //   min-width: 100px;
  // }
  .discountBg {
    // top: -11.5vw;
    left: -10vw;
  }
}

@media (max-width: 400px) {
  // .discountBg {
  //   left: -40px;
  //   top: -70px;
  // }
}

@media (max-width: 400px) {
  // ._inline-style input[type="text"] {
  //   float: none !important;
  // }

  ._button-wrapper {
    margin-top: 0 !important;
  }

  ._row input[type="checkbox"]+span label {
    font-size: 3vw;
  }
}

@media (max-width: 370px) {
  .imgHomeSection {
    position: relative;
    // right: 0;
    height: 100%;
  }

  .formBgSize {
    max-width: 90%;
  }
}