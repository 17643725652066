/*************************************************************************
*
* Customize Bootstrap 4 with our new built-in Sass variables for 
* global style preferences for easy theming and component changes.
*
* Please read more at https://getbootstrap.com/docs/4.0/getting-started/theming/
* 
*************************************************************************/

$theme-colors: (
  primary: #ce0000,
  secondary: #ffffff,
  bgColor: #000000,
);
