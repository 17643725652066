.twerkSectionCol {
  height: 100%;
  position: relative;
}
.twerkSectionCol:nth-child(1) {
  align-items: center;
}
.twerkSectionCol:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.twerkSectionImg1 {
  //   width: 411px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 70%;
}
.twerkSectionImg2 {
  height: 100%;
  width: 400px;
  position: relative;
}
.imgTwerkSection {
  // position: relative;
  // left: 30px;
  height: 45vh;
}
.twerkealoLogo {
  height: 90%;
  max-width: 100%;
}

// TIMER
.timerStyle {
  color: #fff;
  font-size: 46px;
  position: relative;
  font-family: "Blackink";
}
.timerStyle span {
  position: relative;
  margin: 20px;
}
.timerStyle span::after {
  bottom: -10px;
  font-size: 11px;
  letter-spacing: 3.24px;
  text-transform: uppercase;
}

.timerDays::after {
  content: "Días";
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
.timerHours::after {
  content: "Horas";
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
.timerMinutes::after {
  content: "Minutos";
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
.timerSeconds::after {
  content: "Segundos";
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

@media (max-height: 700px) and (min-width: 1440px) {
  .imgTwerkSection {
    height: 43vh;
  }
}
@media (max-height: 1000px) and (min-height: 700px) {
  .imgTwerkSection {
    position: relative;
    left: 10% !important;
  }
}
@media (min-height: 780px) {
  .imgTwerkSection {
    position: relative;
    // left: 50%;
    height: 45vh;
    // transform: translate(-32.5%) !important;
  }
}

@media (max-width: 991px) {
  .twerkealoLogo {
    height: 90%;
    max-width: 100%;
    width: 90%;
    padding-top: 50px;
  }
  .timerStyle {
    color: #fff;
    font-size: 10vw;
    position: relative;
    font-family: "Blackink";
  }
}

@media (max-height: 700px) and (min-width: 991px) and (max-width: 1440px) {
  .imgTwerkSection {
    height: 45vh;
  }
}

@media (max-width: 800px) and (min-width: 991px) {
  .twerkSectionImg2 {
    width: 350px !important;
  }
}
@media (max-width: 700px) {
  .timerStyle span::after {
    bottom: -10px;
    font-size: 2vw;
    letter-spacing: 3.24px;
    text-transform: uppercase;
  }
}
@media (max-height: 600px) {
  .imgTwerkSection {
    left: 0 !important;
  }
}
